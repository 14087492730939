
import { mapGetters } from "vuex";
import { User } from "~/models";

export default {
  data() {
    return {
      clipped: false,
      drawer: true,
      fixed: false,
      accountLoading: false,
      accountMenuItems: [
        {
          icon: "mdi-account-circle",
          title: "My Account",
          to: "/my-account",
        },
        {
          icon: "mdi-drama-masks",
          title: "Stop Impersonation",
          click: function () {
            this.accountLoading = true;
            User.endSwitchUser().then(() => {
              this.accountLoading = false;
              window.location.href = this.$nuxt.$config.baseURL;
            });
          }.bind(this),
          visibility: () => {
            return this.$auth.user.impersonating;
          },
        },
        {
          icon: "mdi-exit-to-app",
          title: "Logout",
          click: function () {
            this.accountLoading = true;
            this.$auth.logout().then(() => {
              this.accountLoading = false;
              this.$router.push({
                path: "/login",
              });
            });
          }.bind(this),
        },
      ],
      allPrimaryMenuItems: [
        {
          icon: "mdi-badge-account-horizontal",
          title: "Licensed Trainers",
          to: "/licensed-trainers",
          visibility: ["admin", "distributor"],
        },
        {
          icon: "mdi-account",
          title: "All Trainers",
          to: "/trainers",
          visibility: ["admin"],
        },
        {
          icon: "mdi-account",
          title: "My Trainer Profile(s)",
          to: () => {
            return this.$auth.user.trainer_ids &&
              this.$auth.user.trainer_ids.length == 1
              ? `/trainers/${this.$auth.user.trainer_ids[0]}`
              : "/my-trainers";
          },
          visibility: () => {
            return (
              this.$auth.user.trainer_ids &&
              this.$auth.user.trainer_ids.length > 0
            );
          },
        },
        {
          icon: "mdi-home-city",
          title: "Studios",
          to: "/studios",
          visibility: ["admin"],
        },
        {
          icon: "mdi-home-city",
          title: "My Studio Profile(s)",
          to: () => {
            return this.$auth.user.studio_ids &&
              this.$auth.user.studio_ids.length == 1
              ? `/studios/${this.$auth.user.studio_ids[0]}`
              : "/my-studios";
          },
          visibility: () => {
            return (
              this.$auth.user.studio_ids &&
              this.$auth.user.studio_ids.length > 0
            );
          },
        },
        {
          icon: "mdi-calendar",
          title: "Training Events",
          to: "/events",
          visibility: () => {
            return this.$auth.user.is_instructor || this.$auth.user.is_admin;
          },
        },
        {
          icon: "mdi-monitor-account",
          title: "Virtual Classes",
          to: "/virtual-classes",
          visibility: () => {
            return this.$auth.user.is_active || this.$auth.user.is_admin;
          },
        },
        {
          icon: "mdi-folder-multiple-image",
          title: "Resource Library",
          to: "/resources?parent=1",
          visibility: [],
        },
        {
          icon: "mdi-file-sign",
          title: "Contracts",
          to: "/contracts",
          visibility: ["admin"],
        },
        {
          icon: "mdi-comment-question-outline",
          title: "Survey Responses",
          to: "/survey-responses",
          visibility: ["admin"],
        },
      ],
      allSecondaryMenuItems: [
        {
          icon: "mdi-account-box",
          title: "My Account",
          to: "/my-account",
          visibility: [],
        },
        {
          icon: "mdi-account-group",
          title: "Users",
          to: "/users",
          visibility: ["admin"],
        },
        {
          icon: "mdi-email-fast",
          title: "User Notifications",
          to: "/notifications",
          visibility: ["admin"],
        },
        {
          icon: "mdi-trash-can-outline",
          title: "Deleted Entries",
          visibility: ["admin"],
          subItems: [
            {
              icon: "mdi-account",
              title: "Trainers",
              to: "/deleted/Trainer",
              visibility: ["admin"],
            },

            {
              icon: "mdi-home-city",
              title: "Studios",
              to: "/deleted/Studio",
              visibility: ["admin"],
            },
            {
              icon: "mdi-account-group",
              title: "Users",
              to: "/deleted/User",
              visibility: ["admin"],
            },
            {
              icon: "mdi-calendar",
              title: "Training Events",
              to: "/deleted/Event",
              visibility: () => {
                return (
                  this.$auth.user.is_instructor || this.$auth.user.is_admin
                );
              },
            },

            {
              icon: "mdi-monitor-account",
              title: "Virtual Classes",
              to: "/deleted/PublicClass",
              visibility: () => {
                return this.$auth.user.is_active || this.$auth.user.is_admin;
              },
            },

            {
              icon: "mdi-folder-multiple-image",
              title: "Resource Library",
              to: "/deleted/Resource",
              visibility: ["admin"],
            },

            {
              icon: "mdi-file-sign",
              title: "Contracts",
              to: "/deleted/Contract",
              visibility: ["admin"],
            },

            {
              icon: "mdi-school",
              title: "Courses",
              to: "/deleted/Course",
              visibility: ["admin"],
            },
            {
              icon: "mdi-ladder",
              title: "Equipment",
              to: "/deleted/Equipment",
              visibility: ["admin"],
            },
            {
              icon: "mdi-certificate-outline",
              title: "Qualifications",
              to: "/deleted/Qualification",
              visibility: ["admin"],
            },

            {
              icon: "mdi-email-edit",
              title: "Email Templates",
              to: "/deleted/MailerTemplate",
              visibility: ["admin"],
            },
            {
              icon: "mdi-shape",
              title: "Course Categories",
              to: "/deleted/CourseCategory",
              visibility: ["admin"],
            },
          ],
        },
        // {
        //   icon: "mdi-email-arrow-right",
        //   title: "Mailer Log",
        //   to: "/mailer-events",
        //   visibility: ["admin"],
        // },
      ],
      allSystemMenuItems: [
        {
          icon: "mdi-school",
          title: "Courses",
          to: "/courses",
          visibility: ["admin"],
        },
        {
          icon: "mdi-ladder",
          title: "Equipment",
          to: "/equipment",
        },
        {
          icon: "mdi-certificate-outline",
          title: "Qualifications",
          to: "/qualifications",
        },
        {
          icon: "mdi-earth",
          title: "Countries",
          to: "/countries",
        },
        {
          icon: "mdi-translate-variant",
          title: "Languages",
          to: "/languages",
        },
        {
          icon: "mdi-email-edit",
          title: "Email Templates",
          to: "/mailer-templates",
        },
        {
          icon: "mdi-shape",
          title: "Course Categories",
          to: "/course-categories",
        },
        {
          icon: "mdi-shape",
          title: "Contract Types",
          to: "/contract-types",
        },
        {
          icon: "mdi-shape",
          title: "Resource Types",
          to: "/resource-types",
        },
        {
          icon: "mdi-tag",
          title: "Country Tags",
          to: "/country-tags",
        },
        {
          icon: "mdi-tag",
          title: "Course Tags",
          to: "/course-tags",
        },
        {
          icon: "mdi-tag",
          title: "Studio Tags",
          to: "/studio-tags",
        },
        {
          icon: "mdi-tag",
          title: "Trainer Tags",
          to: "/trainer-tags",
        },
        {
          icon: "mdi-tag",
          title: "User Tags",
          to: "/user-tags",
        },
      ],
      miniVariant: false,
    };
  },
  computed: {
    ...mapGetters("app", ["title"]),
    activePrimaryMenuItems() {
      return this.allPrimaryMenuItems.filter((item) =>
        this.showItemForCurUser(item, this.$auth.user)
      );
    },
    activeSecondaryMenuItems() {
      return this.allSecondaryMenuItems.filter((item) =>
        this.showItemForCurUser(item, this.$auth.user)
      );
    },
    activeAccountMenuItems() {
      return this.accountMenuItems.filter((item) =>
        this.showItemForCurUser(item, this.$auth.user)
      );
    },
    showSystemMenu() {
      return this.$auth.user.is_admin;
    },
    userName() {
      if (!this.$auth.loggedIn) return "";
      return this.$auth.user.name;
    },
    appClasses() {
      const isRegularUser = !this.$auth.user.is_admin;
      return {
        "user-role--regular": isRegularUser,
        "user-role--admin": this.$auth.user.is_admin,
        "user-role--distributor": this.$auth.user.is_super_user,
      };
    },
  },
  methods: {
    showItemForCurUser: function (item, user) {
      if (!item.visibility) return true;

      if (typeof item.visibility == "function") return item.visibility(user);

      if (user.is_admin) return item.visibility.indexOf("nonAdminOnly") === -1;

      if (item.visibility.indexOf("distributor") !== -1)
        return user.is_super_user;

      return item.visibility.indexOf("admin") === -1;
    },
  },
  mounted() {},
};
